import request from '@/axios';

export function queryRaffleAwardList(strategyId) {
    return request.post(
        'api/v1/raffle/query_raffle_award_list',
        {strategyId: strategyId});
}

export function randomRaffle(strategyId) {
    return request.post(
        'api/v1/raffle/random_raffle', {strategyId: strategyId});
}
