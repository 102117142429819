<template>
  <div class="lottery">
    <vue-particles
        class="background-particles"
        color="#1e88e5"
        :particleOpacity="0.7"
        :particlesNumber="80"
        shapeType="circle"
        :particleSize="4"
        linesColor="#1e88e5"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
    >
    </vue-particles>
    <div class="background-decor"></div>
    <div class="overlay"></div>
    <div class="header">
      <div class="neon-text">

      </div>
    </div>
    <div class="content">
      <LuckyWheel
          ref="myLucky"
          width="300px"
          height="300px"
          :prizes="prizes"
          :blocks="blocks"
          :buttons="buttons"
          @start="startCallback"
          @end="endCallback"
      />
    </div>
    <div class="footer">
      <div class="neon-text">
        幸运大转盘 &copy; 2024 抽奖
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/js/big_market'

export default {
  name: 'Big_market',
  data() {
    return {
      blocks: [{padding: '13px', background: '#617df2'}],
      prizes: [
        {fonts: [{text: '0', top: '10%'}], background: '#e9e8fe'},
        {fonts: [{text: '1', top: '10%'}], background: '#b8c5f2'},
        {fonts: [{text: '2', top: '10%'}], background: '#e9e8fe'},
        {fonts: [{text: '3', top: '10%'}], background: '#b8c5f2'},
        {fonts: [{text: '4', top: '10%'}], background: '#e9e8fe'},
        {fonts: [{text: '5', top: '10%'}], background: '#b8c5f2'},
        {fonts: [{text: '6', top: '10%'}], background: '#b8c5f2'},
        {fonts: [{text: '7', top: '10%'}], background: '#b8c5f2'},
      ],
      buttons: [{
        radius: '35%',
        background: '#8a9bf3',
        pointer: true,
        fonts: [{text: '开始', top: '-10px'}]
      }],
    }
  },
  created() {
    api.queryRaffleAwardList("100006")
        .then(res => {
          // console.log(res,'res')
          if (res.data.code != "0000") {
            window.alert("获取抽奖奖品列表失败 code:" + res.data.code + " info:" + res.data.info)
            return;
          } else {
            this.prizes = res.data.data.map((award, index) => {
              const background = index % 2 === 0 ? '#e9e8fe' : '#b8c5f2';
              return {
                background: background,
                fonts: [{id: award.awardId, text: award.awardTitle, top: '15px'}]
              };
            });
          }
        }).catch(err => {
      // console.log(err,'err')
      window.alert("奖品列表接口访问不通:" + err)
    })
  },
  methods: {
    // 点击抽奖按钮会触发start回调
    startCallback() {
      // 调用抽奖组件的play方法开始游戏
      this.$refs.myLucky.play()
      // 模拟调用接口异步抽奖
      setTimeout(() => {
        // 假设后端返回的中奖索引是0
        api.randomRaffle("100006")
            .then((res) => {
              let index = res.data.data.awardIndex ?
                  res.data.data.awardIndex -1  : this.prizes.findIndex(prize =>
                  //@ts-ignore
                  prize.fonts.some(font => font.id ===   res.data.data.awardId)
              );

              // 调用stop停止旋转并传递中奖索引
              this.$refs.myLucky.stop(index)

            }).catch(err => {
          window.alert("抽奖失败:" + err)
        })
      }, 3000)
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      alert('恭喜你抽到【' + prize.fonts[0].text + '】奖品ID【' + prize.fonts[0].id + '】')
      // console.log(prize)
    },
  }
}
</script>

<style scoped>
.lottery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #fff;
  position: relative;
  overflow: hidden;
  background: transparent; /* 设置背景透明 */
}

.background-particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2; /* 确保粒子背景在最上层 */
}

.background-decor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0); /* 半透明背景以确保内容可读 */
  z-index: 1; /* 背景在内容之下 */
}

.header {
  position: absolute;
  top: 20px; /* 调整这个值来控制距离顶部的距离 */
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  margin-bottom: 0; /* 移除底部外边距 */
}

.neon-text {
  font-size: 2.5em;
  color: #006fff;
  /* 添加不同的文本阴影效果 */
  //text-shadow: 0 0 10px rgba(0, 42, 255, 0.8), 0 0 20px rgba(0, 89, 255, 0.8), 0 0 30px rgba(47, 0, 255, 0.8), 0 0 40px rgba(8, 0, 255, 0.8);
}

.content {
  z-index: 3;
}

.footer {
  width: 100%;
  text-align: center;
  z-index: 3;
  position: absolute;
  bottom: 20px;
}

.footer .neon-text {
  font-size: 1em;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  pointer-events: none; /* 允许点击穿透 */
}
</style>
