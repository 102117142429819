import axios from 'axios';

// 创建一个Axios实例
const instance = axios.create({
    baseURL: "/server", // 设置基础URL
    timeout: 5000, // 设置请求超时时间
});


// 请求拦截器
instance.interceptors.request.use(
    config => {
        // 在请求发送之前可以在这里进行一些处理，如添加请求头
        config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
        return config;
    },
    error => {
        // 处理请求错误
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    response => {
        // 在响应返回之前可以在这里进行一些处理，如处理错误
        // if (response.data.code !== 200) {
        //     return Promise.reject(response.data.message);
        // }
        return response;
    },
    error => {
        // 处理响应错误
        return Promise.reject(error);
    }
);

const request = {
    get(url, params = {}) {
        return instance.get(url, { params });
    },
    post(url, data) {
        return instance.post(url, data);
    },
    put(url, data) {
        return instance.put(url, data);
    },
    upload(url, file) {
        const formData = new FormData();
        formData.append('file', file);

        return instance.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
};

export default request;
