<template>
  <div id="app">
    <BigMarket/>
  </div>
</template>

<script>
import BigMarket from './components/BigMarket.vue'

export default {
  name: 'App',
  components: {
    BigMarket
  }
}
</script>

<style>
#app {
  text-align: center;
  color: #2c3e50;
  width: 100%;
}
</style>
