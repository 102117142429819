import Vue from 'vue'
import App from './App.vue'
import TDesign from 'tdesign-vue';

// 引入组件库的少量全局样式变量
import 'tdesign-vue/es/style/index.css';

Vue.config.productionTip = false
Vue.use(TDesign);

// 完整加载
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)

//粒子组件
import VueParticles from 'vue-particles'
Vue.use(VueParticles)

import axios from 'axios'
Vue.prototype.$axios = axios

new Vue({
  render: h => h(App),
}).$mount('#app')
